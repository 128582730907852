export default {
    methods: {
        async getRecaptcha(action) {
            await this.$recaptchaLoaded();

            const recaptchaToken = await this.$recaptcha(action.replaceAll('-', '_'));

            return recaptchaToken;
        },
    },
};
