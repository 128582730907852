<template>
    <div class="verification-code">
        <div class="verification-code__item" v-for="index in [...Array(length).keys()]" :key="index">
            <input-component
                v-model="inputs[index]"
                :ref="`input_${index}`"
                autocomplete="one-time-code"
                inputmode="numeric"
                placeholder="•"
                type="number"
                @input.capture="handleInput($event, index)"
                @keydown.8="handleDelete($event, index)"
                @keydown.46="handleDelete($event, index)"
                @paste.prevent="handlePaste($event, index)"
            />
        </div>
    </div>
</template>

<script>
    import InputComponent from '@/components/inputs/InputComponent';

    const CODE_LENGTH = 6;

    export default {
        name: 'VerificationCode',
        components: {
            InputComponent,
        },
        props: {
            value: [Number, String],
        },
        methods: {
            handleDelete(event, index) {
                event.preventDefault();

                if (!this.inputs[index]) {
                    this.$refs[`input_${index}`][0].$refs.input.blur();

                    if (this.$refs[`input_${index - 1}`]) {
                        this.$refs[`input_${index - 1}`][0].$refs.input.focus();
                    }
                }

                this.inputs[index] = null;

                this.sendValue();

                this.$forceUpdate();
            },
            handleInput(event, index) {
                const value = event instanceof InputEvent ? event.target.value : event;

                this.inputs[index] = value.toString().length > 1 ? value.toString().replace(this.inputs[index], '').slice(-1) : value;

                if (value) {
                    this.$refs[`input_${index}`][0].$refs.input.blur();

                    if (this.$refs[`input_${index + 1}`]) {
                        this.$refs[`input_${index + 1}`][0].$refs.input.focus();
                    }
                }

                this.sendValue();

                this.$forceUpdate();
            },
            handlePaste(event, index) {
                for (const [digitIndex, digit] of event.clipboardData.getData('text').split('').entries()) {
                    if (undefined !== this.inputs[index + digitIndex]) {
                        this.inputs[index + digitIndex] = digit;
                    }
                }

                this.$refs[`input_${index}`][0].$refs.input.blur();

                this.sendValue();

                this.$forceUpdate();
            },
            sendValue() {
                return this.$emit('input', this.inputs.join(''));
            },
        },
        data() {
            return {
                length: CODE_LENGTH,
                inputs: Array.from({ length: CODE_LENGTH }, () => null),
            };
        },
    };
</script>

<style lang="scss" scoped>
    .verification-code {
        display: grid;
        grid-gap: 0.8rem;
        grid-template-columns: repeat(6, 1fr);

        ::v-deep {
            .input_medium .input__input {
                text-align: center;
                height: 6.8rem;
                padding: 0;
                font-family: 'Gotham Medium', sans-serif;
                font-size: 2.4rem;
                font-weight: 350;

                &::placeholder {
                    font-size: 1.8rem;
                }

                &:focus::placeholder {
                    color: transparent;
                }
            }
        }
    }
</style>
