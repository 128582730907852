<template>
    <div class="timer">
        <template v-if="inline">{{ minuteDozens }}{{ minuteUnits }}:{{ secondDozens }}{{ secondUnits }}</template>

        <template v-else>
            <span class="timer__item">{{ minuteDozens }}</span>
            <span class="timer__item">{{ minuteUnits }}</span>
            <span class="timer__separator">:</span>
            <span class="timer__item">{{ secondDozens }}</span>
            <span class="timer__item">{{ secondUnits }}</span>
        </template>
    </div>
</template>

<script>
    export default {
        name: 'Timer',
        props: {
            inline: {
                type: Boolean,
                default: false,
            },
            value: {
                type: Number,
                required: true,
            },
        },
        computed: {
            seconds() {
                return this.secondsRemaining % 60;
            },
            secondDozens() {
                return Math.floor(this.seconds / 10);
            },
            secondUnits() {
                return this.seconds % 10;
            },
            minutes() {
                return Math.floor(this.secondsRemaining / 60);
            },
            minuteDozens() {
                return Math.floor(this.minutes / 10);
            },
            minuteUnits() {
                return this.minutes % 10;
            },
        },
        methods: {
            checkTimer() {
                if (this.secondsRemaining <= 0) {
                    if (this.interval) {
                        clearInterval(this.interval);
                        this.interval = null;
                    }

                    this.$emit('completed');
                }
            },
        },
        data() {
            return {
                interval: null,
                secondsRemaining: this.value,
            };
        },
        beforeMount() {
            this.checkTimer();

            this.interval = setInterval(() => {
                this.secondsRemaining--;
                this.checkTimer();
            }, 1000);
        },
    };
</script>

<style lang="scss" scoped>
    .timer {
        display: flex;
        align-items: center;
        color: #f5f9ff;
        font-size: 1.4rem;
    }

    .timer__item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 3rem;
        padding: 0.5rem 1rem;
        border-radius: 0.6rem;
        background-color: #2e7c60;

        &:not(:last-child) {
            margin-right: 0.25rem;
        }
    }

    .timer__separator {
        margin-right: 0.25rem;
        color: #a6d6bc;
    }

    @media screen and (min-width: 120em) {
        .timer {
            font-size: 2.4rem;
        }

        .timer__item {
            width: 3rem;
            height: 4.5rem;
            padding: 0.5rem 1rem;

            &:not(:last-child) {
                margin-right: 0.5rem;
            }
        }

        .timer__separator {
            margin-right: 0.5rem;
        }
    }
</style>
